<template>
  <div>
    <app-card
      rounded="0"
      flat
      dark
      color="default"
      class="d-flex justify-center pt-3 pb-6 rounded-b"
    >
      <profile
        class="pb-4"
        showUsername
        :showEdit="true"
        showName
        drawer
        avatarSize="80"
      />
    </app-card>
    <app-card
      raised
      height="64"
      class="
        d-flex
        align-center
        justify-space-around
        mt-n7
        mx-2 mx-sm-4 mx-md-4
      "
    >
      <app-card flat class="d-flex flex-column text-center">
        <span class="text-h6">{{ undoneTasks.length }}</span>
        <span class="text-body-2">Tarefas</span>
      </app-card>
      <app-card flat class="d-flex flex-column text-center">
        <span class="text-h6">{{ photos.length }}</span>
        <span class="text-body-2">Fotos</span>
      </app-card>
      <app-card flat class="d-flex flex-column text-center">
        <span class="text-h6">10</span>
        <span class="text-body-2">Mensagens</span>
      </app-card>
    </app-card>
    <v-row no-gutters class="mt-3 mx-2 mx-sm-4 mx-md-4">
      <v-col cols="12" md="6">
        <image-gallery
          title="Fotos"
          :subtitle="true"
          :images="photos"
        ></image-gallery>
      </v-col>
      <v-col>
        <basic-card>
          <template #title>Tarefas</template>
          <template #subtitle>{{
            `${tasks.length} tarefas (${doneTasks.length} concluídas)`
          }}</template>
          <template #info>
            <v-progress-circular
              :rotate="360"
              :size="80"
              :width="12"
              :value="doneTasksPercent"
              color="teal"
            >
              {{ `${doneTasksPercent}%` }}
            </v-progress-circular>
          </template>

          <v-row no-gutters>
            <v-col cols="12">
              <task-form class="my-4" :showFAB="false" />
            </v-col>
            <v-col cols="12" sm="6">
              <tasklist :items="undoneTasks" title="A Fazer" />
            </v-col>
            <v-col cols="12" sm="6">
              <tasklist :items="doneTasks" title="Concluídas" />
            </v-col>
          </v-row>
        </basic-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BasicCard } from '@/components/layout/cards'
import { Profile } from '../components'

import { getTasks } from '@/modules/widgets/tasks/composables'
import { Tasklist, TaskForm } from '@/modules/widgets/tasks/components'
import ImageGallery from '@/components/widget/ImageGallery'
export default {
  name: 'ViewProfile',

  components: {
    BasicCard,
    Profile,
    ImageGallery,
    Tasklist,
    TaskForm
  },

  setup() {
    const photos = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 14, 18, 16])
    const { error, tasks, undoneTasks, doneTasks, doneTasksPercent } =
      getTasks()

    return {
      error,
      tasks,
      photos,
      doneTasksPercent,
      doneTasks,
      undoneTasks
    }
  }
}
</script>
