<template>
  <div class="d-flex flex-wrap justify-space-around">
    <router-link
      v-for="item in images"
      :key="item.id"
      :to="{ name: 'AlbumDetails', params: { id: item.id } }"
    >
      <v-sheet width="150" max-width="150" max-height="150">
        <v-img
          :aspect-ratio="16 / 9"
          class="ma-1"
          width="150"
          max-width="150"
          :src="item.filePath"
          :lazy-src="`https://picsum.photos/1/2?random=5`"
        />
        <div class="text-body-2 text-center">{{ item.title }}</div>
      </v-sheet>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ImageGallery',

  props: {
    title: {
      type: [String, Boolean],
      default: false
    },
    subtitle: {
      type: [Boolean],
      default: false
    },
    images: {
      type: Array,
      required: true
    }
  }
}
</script>

<style></style>
